import { useState, useEffect, useCallback } from 'react'
import useAxiosPrivate from '../../api/hooks/useAxiosPrivate'
import useToastNotification from '../notifications/useToastNotification'
import { Maintenance } from '../../interfaces/maintenance'
import moment from 'moment'

const useFetchMaintenances = (year: string) => {
  const axiosPrivate = useAxiosPrivate()
  const { showApiErrorMessage } = useToastNotification()
  const [maintenances, setMaintenances] = useState<Maintenance[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleFetchMaintenances = useCallback(
    async (maintenanceType: string) => {
      setIsLoading(true)

      let url = `/api/maintenances?financialYear=${moment(year).format('YYYY')}`
      if (maintenanceType !== '') {
        url += `&type=${maintenanceType}`
      }

      await axiosPrivate
        .get(url)
        .then((response) => {
          setMaintenances(response.data['hydra:member'])
        })
        .catch((error) => {
          showApiErrorMessage(
            error,
            'Erro ao fazer a listagem das manutenções. Por favor, tente novamente.'
          )
        })
        .finally(() => {
          setIsLoading(false)
        })
    },
    [axiosPrivate, showApiErrorMessage, year]
  )

  useEffect(() => {
    handleFetchMaintenances('')
  }, [handleFetchMaintenances])

  return {
    maintenances,
    setMaintenances,
    isLoading,
    handleFetchMaintenances,
  }
}

export default useFetchMaintenances
